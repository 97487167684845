import {get, post} from "@metronic/helpers/api_helper.jsx";

export const orderSecurity = (data) => {
  return post('/securities/order', data)
}

export const getStocksRates = (data) => {
  return post('/securities/get-stock', data)
}

export const getIndicesRates = (data) => {
  return post('/securities/get-index', data)
}

export const getAcquiredSecurities = () => {
  return get('/securities/acquired-list')
}
