import React, {useEffect, useState} from 'react'
import {Button} from "reactstrap"
import {clsx} from "clsx"
import {checkImageValidity, VITE_APP_VARIANT} from "@metronic/helpers/utils.jsx"
import {blockCardTitle, StepsImages} from "@metronic/Components/Securities/Components.jsx"
import {useSecuritiesContext} from "@metronic/Components/Securities/Context/SecuritiesContext.jsx";

const defaultStepsSmallStyle = 'd-flex flex-column flex-md-row justify-content-center justify-content-md-start ' +
  'mt-3 mt-sm-0'
const lightStepsSmallStyle = 'd-flex flex-column flex-md-row flex-lg-column flex-xl-row justify-content-center ' +
  'justify-content-md-start justify-content-lg-center justify-content-xl-start mt-3 mt-sm-0'

const HowToBuy = ({blockId}) => {
  const {
    tabData,
    allListTab,
    setActiveTab,
    activeTab,
    tableRef
  } = useSecuritiesContext()
  const [activeStepTab, setActiveStepTab] = useState('')
  const [stepsData, setStepsData] = useState([])
  const [imageValidity, setImageValidity] = useState([])

  useEffect(() => {
    if (tabData && blockId) {
      const data = tabData.find(i => i.uuid === blockId)?.steps_tabs_list

      setStepsData(data)
      setActiveStepTab(data[0].uuid)
    }
  }, [tabData, blockId])

  const handleClick = (e) => {
    e.preventDefault()

    if (!allListTab) return

    if (allListTab !== activeTab) {
      setActiveTab(allListTab)
    }

    if (allListTab && tableRef.current) {
      setTimeout(() => {
        tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'center'})
      }, 200)
    }
  }

  useEffect(() => {
    const validateImages = async () => {
      const results = {}

      for (const item of stepsData) {
        if (item['img']) {
          results[item.uuid] = await checkImageValidity(item['img'])
          } else {
            results[item.uuid] = false
          }
        }
        setImageValidity(results)
      }

    validateImages()
  }, [stepsData])

  return (
    <>
      <div className="d-flex flex-column">
        {blockCardTitle(tabData.find(i => i.uuid === blockId)?.title)}

        <div className="d-flex">
          <div
            className={clsx('min-h-350px d-flex flex-column justify-content-between bg-light p-5 rounded-3',
              (Object.keys(imageValidity).length && !imageValidity[activeStepTab]) ? 'col-12' :
              VITE_APP_VARIANT === 'light' ? 'col-12 col-md-7 col-lg-12 col-xl-7 col-xl-8' : 'col-12 col-md-7 col-xl-8'
            )}>
            <div className="mt-5 pb-10">
              <ul className="nav nav-tabs nav-pills border-transparent border-0 mb-5 fs-6">
                {(stepsData && stepsData.length) ? stepsData.map((item, idx) => {
                  return (
                    <li key={idx} className="nav-item cursor-pointer fs-3 fw-bold border-0">
                      <a
                        onClick={() => setActiveStepTab(item.uuid)}
                        className={clsx('nav-link me-0 me-sm-6 p-0 px-3', activeStepTab === item.uuid && 'active')}
                        data-bs-toggle="tab"
                      >
                        {item.tab_title}
                      </a>
                    </li>
                  )
                }) : null}
              </ul>
            </div>

            {(stepsData && stepsData.length) ? stepsData.map((item, idx) => {
              return (
                activeStepTab === item.uuid &&
                <div key={idx}>
                  <h3 className="text-gray-800 mb-5">{item.description_title}</h3>
                  {item.description ? item.description.split(/\r?\n/)
                    .map((string, idx) => (
                      <React.Fragment key={idx}>
                        <span>{string}</span> <br/>
                      </React.Fragment>
                    )) : null}
                </div>
              )
            }) : null}

            <div
              className="mt-0 mt-md-10 d-flex align-items-center justify-content-evenly
                    justify-content-md-start flex-column flex-sm-row"
            >
              <div className={clsx('justify-content-center',
                (Object.keys(imageValidity).length && !imageValidity[activeStepTab]) ? 'd-none' :
                VITE_APP_VARIANT === 'light' ? 'd-flex d-md-none d-lg-flex d-xl-none mt-3' : 'd-flex mt-3 d-md-none'
              )}>
                <StepsImages blockId={blockId} activeStepTab={activeStepTab} />
              </div>
              <div
                className={clsx('text-nowrap', VITE_APP_VARIANT === 'light' ?
                  lightStepsSmallStyle : defaultStepsSmallStyle
                )}>
                <Button
                  type="button"
                  className={clsx('btn btn-success',
                    VITE_APP_VARIANT === 'light' ?
                      'px-md-3 py-md-1 mt-2 mt-sm-5 mt-md-0 mt-lg-3 mt-xl-0' :
                      'px-md-3 py-md-1 mt-2 mt-sm-5 mt-md-0'
                  )}
                  onClick={(e) => handleClick(e)}
                >
                  Place an order to buy
                </Button>
              </div>
            </div>
          </div>

          {(Object.keys(imageValidity).length && imageValidity[activeStepTab]) ?
            <div className={clsx('justify-content-center align-items-center',
              VITE_APP_VARIANT === 'light' ?
                'd-none d-md-flex d-lg-none d-xl-flex col-md-5 col-xl-4' :
                'd-none d-md-flex col-md-5 col-xl-4'
            )}>
              <StepsImages blockId={blockId} activeStepTab={activeStepTab} />
            </div> : null
          }


        </div>
      </div>
    </>
  )
}

export default HowToBuy
