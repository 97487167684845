import React, {useContext, useMemo, useRef, useState} from "react";
import {Card, CardBody, Col, Row} from "reactstrap"
import BaseTable from "../Tables/BaseTable.jsx"
import {useTranslation} from "react-i18next"
import {
  cryptoIconsMapper,
  isTwoPrecision,
  prettyDate,
  toTwoPrecision,
  TransactionBadgeIcon, TransactionStatusBadge
} from "@metronic/helpers/utils.jsx"
import {capitalize} from "lodash"
import {TransactionsModalContext} from "@metronic/Components/Transactions/TransactionsDetailsModalsContext.jsx"

const TransactionsTable = () => {
  const {openTransferDetailsModal} = useContext(TransactionsModalContext)
  const baseTableMethodsRef = useRef(null)
  const { t } = useTranslation()
  const [selectedRows, setSelectedRows] = useState([])
  const selectedRowsHandler = (row) => {setSelectedRows(row)}

  const columns = useMemo(
    () => [
      {
        id: "id",
        name: <span className='fw-bold fs-5'>ID</span>,
        selector: row => <a onClick={() => openTransferDetailsModal(row)} className="fw-bold cursor-pointer">#{row.id}</a>,
        sortable: true,
        // minWidth: '90px'
        width: '90px'
      },
      {
        id: "type",
        name: <span className="fw-bold fs-5">{t("Type")}</span>,
        // minWidth: "67px",
        width: "67px",
        selector: row =>
          <TransactionBadgeIcon
            transactionId={row.id}
            transactionType={row.type}
            invoiceId={row.invoice_id}
            invoiceAmount={row.usd_amount}
          />
      },
      {
        id: "date",
        name: <span className='fw-bold fs-5'>{t("Date")}</span>,
        selector: row => <span className="fw-bold">{prettyDate(row.created_at)}</span>,
        width: '170px',
      },
      {
        id: "base_asset",
        name: <span className="fw-bold fs-5 text-nowrap text-truncate">{t("Base Asset")}</span>,
        selector: row => (
          <div className="d-flex align-items-center">
            <div className="h-25px w-25px rounded-circle me-2">
              <img
                src={cryptoIconsMapper[row.base_asset.currency.code]}
                className="img-fluid"
                alt="3"
              />
            </div>
            <span className="fw-bold">{row.base_asset.currency.code}</span>
          </div>
        ),
        // minWidth: "100px",
      },
      {
        id: "quote_asset",
        name: <span className="fw-bold fs-5 text-nowrap text-truncate">{t("Quote Asset")}</span>,
        selector: row => (
          row.quote_asset && row.quote_asset.currency ?
            <div className="d-flex align-items-center">
              <div className="h-25px w-25px rounded-circle me-2">
                <img
                  src={cryptoIconsMapper[row.quote_asset.currency.code]}
                  className="img-fluid"
                  alt="3"
                />
              </div>
              <span className="fw-bold">{row.quote_asset.currency.code}</span>
            </div> :
            '-'
        ),
        // minWidth: "100px",
      },
      {
        id: "amount",
        name: <span className='fw-bold fs-5'>{t("Amount")}</span>,
        selector: row => <span className="fw-bold">{isTwoPrecision(row.base_asset.currency.code ,row.base_amount)}</span>,
        // minWidth: '150px',
      },
      {
        id: "usd_amount",
        name: <span className="fw-bold fs-5 text-nowrap text-truncate">{t("USD Amount")}</span>,
        selector: row => <span className="fw-bold">${toTwoPrecision(row.usd_amount)}</span>,
        // minWidth: '100px'
      },
      {
        id: "fee_pct",
        name: <span className="fw-bold fs-5 text-nowrap">{t("Fee Pct")}</span>,
        selector: row => <span className="fw-bold">{Number(row.fee_pct)}%</span>,
        width: '100px'
      },
      {
        id: "status",
        name: <span className="fw-bold fs-5 text-nowrap">{t("Status")}</span>,
        selector: row => (
          <TransactionStatusBadge status={row.status} color={row.status_color} />
        ),
        // minWidth: '115px',
      }
    ], [t])

  return (
    <>
      <Row className="g-5 g-xl-10 mb-5 mb-xl-10">
        <Col className="col-12">
          <Card>
            <CardBody>
              <BaseTable
                columns={columns}
                title={t("Transactions")}
                selectableRows={false}
                methodsRef={baseTableMethodsRef}
                selectedRowsHandler={selectedRowsHandler}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default TransactionsTable
