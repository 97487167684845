import React from 'react'
import {usePage} from "@inertiajs/react"
import {prettyDate} from "@metronic/helpers/utils.jsx";

const OverviewTab = () => {
  const {props: pageProps} = usePage()
  const profileData = pageProps.profile_data

  const noInfoText = () => {
    return (
      <span className="text-gray-500">No information</span>
    )
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Profile Details</h3>
          </div>
        </div>

        <div className='card-body p-9'>
          <div className='row mb-6'>
            <label className='col-lg-4 fw-bold text-muted'>Full Name</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-gray-900'>{profileData?.first_name} {profileData?.last_name}</span>
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 fw-bold text-muted'>
              Phone Number
            </label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 me-2'>{profileData?.phone ? profileData?.phone : noInfoText()}</span>
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 fw-bold text-muted'>Email Address</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{profileData?.email ? profileData?.email : noInfoText()}</span>
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 fw-bold text-muted'>City</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{profileData?.city ? profileData?.city : noInfoText()}</span>
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 fw-bold text-muted'>
              Country
            </label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-gray-900'>
                {profileData?.customer_country ? profileData?.customer_country : noInfoText()}
              </span>
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 fw-bold text-muted'>Canton</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-gray-500'>{profileData?.state ? profileData?.state : noInfoText()}</span>
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 fw-bold text-muted'>Address</label>

            <div className='col-lg-8'>
              <span className='fw-bold fs-6'>{profileData?.address ? profileData?.address : noInfoText()}</span>
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 fw-bold text-muted'>Postal Code</label>

            <div className='col-lg-8'>
              <span className='fw-bold fs-6'>{profileData?.postal_code ? profileData?.postal_code : noInfoText()}</span>
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 fw-bold text-muted'>Birthdate</label>

            <div className='col-lg-8'>
              <span className='fw-bold fs-6'>{profileData?.birthday ? prettyDate(profileData?.birthday, false) : noInfoText()}</span>
            </div>
          </div>

          <div className='row py-3 bg-light rounded-2'>
            <label className='col-lg-4 fw-bold text-muted'>Details</label>

            <div className='col-lg-8'>
              <span className='fw-bold text-gray-500 fs-6'>{profileData?.details ? profileData?.details : noInfoText()}</span>
            </div>
          </div>


          {/*<div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>*/}
          {/*  <KTIcon iconName='information-5' className='fs-2tx text-warning me-4' />*/}
          {/*  <div className='d-flex flex-stack flex-grow-1'>*/}
          {/*    <div className='fw-bold'>*/}
          {/*      <h4 className='text-gray-800 fw-bolder'>We need your attention!</h4>*/}
          {/*      <div className='fs-6 text-gray-600'>*/}
          {/*        Your payment was declined. To start using tools, please*/}
          {/*        <Link className='fw-bolder' to='/crafted/account/settings'>*/}
          {/*          {' '}*/}
          {/*          Add Payment Method*/}
          {/*        </Link>*/}
          {/*        .*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </>
  )
}

export default OverviewTab
