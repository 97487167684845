import axios from "axios";
import {toast} from "react-toastify";

export async function get(url, data) {
  const response = await fetch(`${url}?` + new URLSearchParams(data).toString(), {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Accept": "application/json",
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *client
  });

  return await response.json(); // parses JSON response into native JavaScript objects
}

export async function post(url, data) {
  const response = await axios.post(url, data)

  if (response.data.success === false) {
    if (response.data.errors) {
      Object.values(response.data.errors).forEach(i => i.forEach(error => toast.error(error)))
      return 'Error'
    }
  }

  return response.data
}

window.addEventListener('beforeunload', function () {
  get('/go-offline').then(r => console.log('Offline'))
})

window.addEventListener('load', function () {
  get('/visit').then(r => console.log('Online'))
})
