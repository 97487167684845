import React, {useContext, useEffect, useRef, useState} from 'react'
import {Button, Col} from "reactstrap"
import {ChartsWidget3} from "@metronic/_metronic/partials/widgets/index"
import {useSecuritiesContext} from "@metronic/Components/Securities/Context/SecuritiesContext.jsx"
import {capitalize} from "lodash"
import {getIndicesRates, orderSecurity} from "@metronic/api/securities.js"
// import {toast} from "react-toastify"
import {getStocksRates} from "@metronic/api/securities.js"
// import {reloadData} from "@metronic/helpers/utils.jsx"
import {ModalContext} from "@metronic/Components/Modal/index.jsx"
import BuySecuritiesModal from "@metronic/Components/Securities/Modals/BuySecuritiesModal.jsx"
import moment from "moment-timezone"
import {toTwoPrecision} from "@metronic/helpers/utils.jsx"

const SecurityDetail = (props) => {
  const {
    selectedSecurities,
    resetSelectedSecurities,
    isOwnDetails = false,
    acquiredSecurity = null
  } = props
  const [securityData, setSecurityData] = useState([])
  const {ownListTab, setActiveTab, activeTab, setOwnSelectedSecurities, ownSelectedSecurities} = useSecuritiesContext()
  const [securityTicker, setSecurityTicker] = useState('')
  const [securityTickerData, setSecurityTickerData] = useState([])
  const [tickerType, setTickerType] = useState('')
  const [tickerRange, setTickerRange] = useState('month')
  const {openModal} = useContext(ModalContext)
  const detailsRef = useRef(null)

  // const makeSecuritiesOrder = () => {
  //   orderSecurity({security_id: selectedSecurities?.id}).then(r => {
  //     toast.success(r.message)
  //     reloadData(['customer_securities'])
  //   })
  // }

  useEffect(() => {
    if (selectedSecurities && selectedSecurities.meta) {
      let data = Object.entries(selectedSecurities?.meta)

      if (data && data.length) {
        let tickerBlock = data.find(i => i[0] === 'ticker')

        if (tickerBlock) {
          setTickerType(selectedSecurities.type)
          setSecurityTicker(tickerBlock[1]?.value)
        } else {
          setTickerType('')
          setSecurityTicker(null)
        }
      }

      const filteredData = data.filter(([key, value]) => !value?.hidden_in_details)
      setSecurityData(filteredData.map(item => (
        {[item[0]]: item[1]}
      )))
    }
  }, [selectedSecurities])

  const getStocks = (data) => {
    getStocksRates(data).then(r => {
      setSecurityTickerData(r.original)
    })
  }

  const openBuySecuritiesModal = (selectedSecurities) => {
    openModal({
      title: 'Create order',
      content: <BuySecuritiesModal
        setOwnSelectedSecurities={setOwnSelectedSecurities}
        selectedSecurities={selectedSecurities}
        ownListTab={ownListTab}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
      />,
      size: 'lg'
    })
  }

  const getIndices = (data) => {
    getIndicesRates(data).then(r => {
      setSecurityTickerData(r.original)
    })
  }

  useEffect(() => {
    if (securityData && isOwnDetails && acquiredSecurity && selectedSecurities) {
      const maturityDate = moment(selectedSecurities.meta['maturity_date']?.value)
      const dateNow = moment()
      const purchaseDate = moment(acquiredSecurity.created_at)
      const dividendsPerDay =
        (Number(selectedSecurities.meta['coupon_rate']?.value) * Number(selectedSecurities.meta['current_price']?.value)) / 365

      const timeLeft = maturityDate.diff(dateNow, 'days')
      const dividends = (purchaseDate.diff(dateNow, 'days') * -1) * dividendsPerDay

      const privateFields = [
        {quantity: {
            label: 'Quantity',
            value: acquiredSecurity.quantity
          }
        },
        {dividends: {
            label: 'Dividends',
            value: `${toTwoPrecision(dividends)} ${selectedSecurities.meta['payout_asset']?.payout_asset_code || ''}`
          }
        },
        {count: {
            label: 'Count',
            value: timeLeft ? `${timeLeft} days` : '-'
          }
        }
      ]

      setSecurityData(data => [...privateFields, ...data])
    }
  }, [isOwnDetails, acquiredSecurity, selectedSecurities])

  useEffect(() => {
    if (securityData && securityData?.length && securityTicker && tickerRange) {
      if (tickerType && tickerType === 'stocks') {
        getStocks({stock_ticker: securityTicker, range: tickerRange})
      }

      if (tickerType && tickerType === 'indices') {
        getIndices({index_ticker: securityTicker, range: tickerRange})
      }
    }
  }, [selectedSecurities, tickerRange, securityData, securityTicker, tickerType])

  // const setRangeHandler = (range) => {
  //   setTickerRange(range)
  // }

  useEffect(() => {
    if (ownSelectedSecurities.name && detailsRef.current) {
      setTimeout(() => {
        detailsRef.current.scrollIntoView({ behavior: 'smooth', block: 'center'})
      }, 300)
    }
  }, [ownSelectedSecurities, detailsRef.current])

  return (
    <>
      {selectedSecurities?.name ?
        <Col className="col-12">

          <div className="d-flex mb-5 text-nowrap scroll-y d-flex flex-column flex-sm-row
          justify-content-between"
          >
            <Button
              type="button"
              className="btn btn-sm btn-light-success px-10"
              onClick={() => {
                resetSelectedSecurities()
                setTickerType('')
                setSecurityTicker('')
              }}
            >
              <i className="bi bi-chevron-double-left"></i> Back
            </Button>

            <h2 className="text-gray-600 pt-0 pt-sm-2 my-5 my-sm-0 text-center">{selectedSecurities?.name}</h2>

            <Button
              type="button"
              color="primary"
              className="btn btn-sm btn-primary px-10 fw-bold"
              // disabled={!!(purchaseList.length && purchaseList.includes(selectedSecurities.id))}
              onClick={() => openBuySecuritiesModal(selectedSecurities)}
            >
              Buy
            </Button>
          </div>

          {(securityData && securityData?.length && securityTicker && tickerType) ?
            <ChartsWidget3
              className="mb-15"
              data={securityTickerData}
              tickerType={tickerType}
              selectedSecurities={selectedSecurities}
              // setRangeHandler={setRangeHandler}
              tickerRange={tickerRange}
            /> : null
          }

          <div className="text-nowrap overflow-scroll" ref={detailsRef}>
            {(securityData && securityData.length) ? securityData.map((item, idx) => {
              const [key, values] = Object.entries(item)[0]
              const assetCode =
                (key === 'current_price' && selectedSecurities.meta['payout_asset']?.payout_asset_code) ?
                  selectedSecurities.meta['payout_asset']?.payout_asset_code : ''

              return (
                <React.Fragment key={idx}>
                  <div className="d-flex flex-column flex-sm-row justify-content-between pt-4 pb-3 px-3">
                    <span className="fs-5 fs-sm-3 fw-bold text-gray-600 min-w-250px">
                       {values.label}
                    </span>
                    <span className="fs-5 fs-sm-3 fw-bold text-gray-800">
                      {(values.type === 'select' && values.value) ? capitalize(values.value) : values.value || '-'} {assetCode}
                    </span>
                  </div>
                  <div className="separator text-gray-600"></div>
                </React.Fragment>
              )
            }) : null}
          </div>
        </Col> : null
      }
    </>
  )
}

export default SecurityDetail
