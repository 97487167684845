import React, {useContext} from 'react'
import {Spinner, Table} from "reactstrap"
import {
  cryptoIconsMapper,
  isTwoPrecision,
  prettyDate,
  printTransactionType,
  toTwoPrecision,
  TransactionStatusBadge
} from "@metronic/helpers/utils.jsx"
import {ModalContext} from "@metronic/Components/Modal/ModalsContext/ModalContext.jsx"
import {useTranslation} from "react-i18next"
import {capitalize} from "lodash"

const TransactionsDetailsModal = ({selectedTransaction}) => {
  const {closeModal} = useContext(ModalContext)
  const {t} = useTranslation()

  const transaction = selectedTransaction

  const baseAssetCode = transaction?.base_asset?.currency?.code
  const quoteAssetCode = transaction?.quote_asset?.currency.code
  const type = transaction?.type
  const quoteAmount = transaction?.quote_amount
  const baseAmount = transaction?.base_amount
  const totalFee = transaction?.total_fee
  const usdAmount = transaction?.usd_amount
  const status = transaction?.status || ''

  return (
    <React.Fragment>
      {selectedTransaction ?
        (
          <div>
            <Table responsive className="table-striped mb-3">
              <tbody>
              <tr className="fw-bold">
                <td>ID</td>
                <td className="text-gray-700">#{transaction.id}</td>
              </tr>
              <tr className="fw-bold">
                <td>{transaction.invoice_id ? 'Payout Asset' : 'Base Asset'}</td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="h-25px w-25px rounded-circle me-2">
                      <img
                        src={cryptoIconsMapper[baseAssetCode]}
                        className="img-fluid"
                        alt="3"
                      />
                    </div>
                    <span className="text-gray-700">{baseAssetCode}</span>
                  </div>
                </td>
              </tr>
              {type === 'exchange' ?
                <>
                  <tr className="fw-bold">
                    <td>{t("Buy amount")}</td>
                    <td className="text-gray-700">
                      {isTwoPrecision(baseAssetCode, baseAmount)}
                    </td>
                  </tr>
                  <tr className="fw-bold">
                    <td>{t("Quote Asset")}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="h-25px w-25px rounded-circle me-2">
                          <img
                            src={cryptoIconsMapper[quoteAssetCode]}
                            className="img-fluid"
                            alt="3"
                          />
                        </div>
                        <span className="text-gray-700">{quoteAssetCode}</span>
                      </div>
                    </td>
                  </tr>
                  <tr className="fw-bold">
                    <td>{t("Sell amount")}</td>
                    <td className="text-gray-700">
                      {isTwoPrecision(quoteAssetCode, quoteAmount)}
                    </td>
                  </tr>
                </>
                :
                <tr className="fw-bold">
                  <td>{t("Amount")}</td>
                  <td className="text-gray-700">
                    {isTwoPrecision(baseAssetCode, baseAmount)}
                  </td>
                </tr>
              }
              <tr className="fw-bold">
                <td>{t("Type")}</td>
                <td className="text-gray-700">
                  {t(`${
                    printTransactionType(capitalize(transaction.invoice_id && Number(usdAmount) < 0 ? 'invoice' : type))}`)}
                </td>
              </tr>
              <tr className="fw-bold">
                <td>{t("Status")}</td>
                <td>
                  <TransactionStatusBadge status={status} color={transaction.status_color} />
                </td>
              </tr>
              <tr className="fw-bold">
                <td>{t("Fee")}</td>
                <td className="text-gray-700">
                  {transaction.invoice_id ? '0%' : (
                    <span>
                      {Number(transaction.fee_pct)}%
                      {type === 'deduction' ? (
                        <span>&nbsp;({isTwoPrecision(baseAssetCode, totalFee)} {baseAssetCode})</span>
                      ) : (
                        type === 'exchange' ? (
                          <span>
															&nbsp;({isTwoPrecision(quoteAssetCode, totalFee)} {quoteAssetCode})
														</span>
                        ) : null
                      )}
   										 </span>
                  )}
                </td>
              </tr>
              {type === 'exchange' ? (
                <>
                  <tr className="fw-bold">
                    <td>{t("Buy amount in USD")}</td>
                    <td className="text-gray-700">{toTwoPrecision(usdAmount)}$</td>
                  </tr>
                  <tr className="fw-bold">
                    <td>{t("Total sell amount")}</td>
                    <td className="text-gray-700">
                      {isTwoPrecision(quoteAssetCode,
                        (Number(quoteAmount) + Number(totalFee)))}
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  <tr className="fw-bold">
                    <td>{t("USD Amount")}</td>
                    <td className="text-gray-700">{toTwoPrecision(usdAmount)}$</td>
                  </tr>
                  <tr className="fw-bold">
                    <td>{t("Total")}</td>
                    {type === 'deduction' ?
                      <td className="text-gray-700">
                        {isTwoPrecision(baseAssetCode, (Number(baseAmount) + Number(totalFee)))}
                      </td>
                      :
                      <td className="text-gray-700">{isTwoPrecision(baseAssetCode, baseAmount)}</td>
                    }
                  </tr>
                </>
              )
              }

              <tr className="fw-bold">
                <td>{t("Date")}</td>
                <td>{prettyDate(transaction.created_at)}</td>
              </tr>
              </tbody>
            </Table>

            {transaction?.meta?.comment ? (
              <div className="border-top-dashed border-bottom-dashed border-top border-bottom pt-3">
                {transaction?.meta?.comment === '' || false ? null :
                  <Table responsive className="table-striped">
                    <tbody>
                    <tr>
                      <td className="fw-semibold font-italic text-muted">{t("Comment")}:</td>
                      <td className="text-muted">{transaction?.meta?.comment}</td>
                    </tr>
                    </tbody>
                  </Table>
                }
              </div>
            ) : null}

            {/*<div className="d-flex justify-content-end">*/}
            {/*	<Button color="primary" className="btn btn-sm" onClick={closeModal}>*/}
            {/*		Close*/}
            {/*	</Button>*/}
            {/*</div>*/}

          </div>
        ) : (
          <div className="d-flex flex-column align-items-center m-4">
            <span className="d-flex align-items-center">
              <Spinner size="sm" className="flex-shrink-0"></Spinner>
              <span className="flex-grow-1 ms-2 fs-16">{t("Loading")}...</span>
            </span>
          </div>
        )
      }
    </React.Fragment>
  )
}

export default TransactionsDetailsModal
