import React, {useEffect, useRef, useState} from 'react'
import clsx from "clsx"
import {copyChatMessage, prettyDate} from "@metronic/helpers/utils.jsx"
import defaultAvatar from "@metronic/assets/images/users/user-dummy-img.jpg"
import {usePage} from "@inertiajs/react"

const ChatBodyScrollBar = (props) => {
  const {data, initialMessage, isChatOpen = false} = props
  const [messageId, setMessageId] = useState('')
  const [currentAvatar, setCurrentAvatar] = useState(defaultAvatar)
  const [messages, setMessages] = useState([])
  const messagesEndRef = useRef(null)
  const {props: pageProps} = usePage()
  const userId = pageProps?.auth?.user?.id
  const currentUser = pageProps?.auth?.user

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  useEffect(() => {
    if (currentUser.profile?.avatar_url) {
      setCurrentAvatar(currentUser.profile.avatar_url)
    } else {
      setCurrentAvatar(defaultAvatar)
    }
  }, [currentUser])

  useEffect(() => {
    if (data && data.length) {
      setMessages(data)
    }
  }, [data])

  useEffect(() => {
    scrollToBottom()
  }, [messages, isChatOpen])

  return (
    <>
      {messages.map((message, index) => {
        // const userInfo = userInfos[message?.user]
        const state = userId !== message?.user_id ? 'primary' : 'success'
        const templateAttr = {}
        if (message?.message) {
          Object.defineProperty(templateAttr, 'data-kt-element', {
            value: `template-${userId !== message?.user_id ? 'in' : 'out'}`,
          })
        }
        const contentClass = `justify-content-${
          userId !== message?.user_id ? 'start' : 'end'
        } mb-10`
        return (
          <div
            key={index}
            // className={clsx('d-flex', contentClass, 'mb-10', {'d-none': message?.message})}
            className={clsx('d-flex', contentClass, Number(index) === 0 ? 'flex-column' : '')}
            {...templateAttr}
          >
            {Number(index) === 0 ?
              <div className='w-100 text-center text-gray-700 fs-5 fw-bold pb-5'>
                Open {prettyDate(message.created_at)}
              </div> : null
            }
            <div
              className={clsx(
                'd-flex flex-column',
                `align-items-${userId !== message?.user_id ? 'start' : 'end'}`,
                ((Number(index) === Number(messages.length - 1)) && (Number(initialMessage?.status) === 4)) && 'w-100'
              )}
            >
              <div className='d-flex align-items-center mb-2'>
                {userId !== message?.user_id ? (
                  <>
                    <div className='symbol symbol-35px symbol-circle '>
                      <img alt='Pic' src={defaultAvatar}/>
                    </div>
                    <div className='ms-3 d-flex flex-column'>
                          <span className='fs-6 fw-bold text-gray-900 me-1'>
                            Support Team
                          </span>
                      <span className='text-muted fs-7 mb-1'>{prettyDate(message?.created_at)}</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='me-3 d-flex flex-column align-items-end'>
                      <a href='#' className='fs-6 fw-bold text-gray-900 text-hover-primary ms-1'>
                        You
                      </a>
                      <div className='text-muted fs-7 mb-1'>{prettyDate(message?.created_at)}</div>
                    </div>
                    <div className='symbol  symbol-35px symbol-circle '>
                      <img alt='Pic' src={currentAvatar}/>
                    </div>
                  </>
                )}
              </div>

              <div
                className={clsx('d-flex flex-row', userId !== message?.user_id ? 'flex-row-reverse' : '')}
                onMouseEnter={() => setMessageId(String(index))}
                onMouseLeave={() => setMessageId('')}
              >
                <span className={clsx('', messageId === String(index) ? 'd-block' : 'd-none')}>
                  {copyChatMessage({text: message.message, title: 'Copy'})}
                </span>
                <div
                  className={clsx(
                    'p-5 rounded keep-lines',
                    `bg-light-${state}`,
                    'text-gray-900 fw-semibold mw-lg-400px',
                    `text-${userId !== message?.user_id ? 'start' : 'end'}`
                  )}
                  data-kt-element='message-text'
                  // dangerouslySetInnerHTML={{__html: message?.message}}
                >
                  {message?.message}
                </div>
              </div>
              {((Number(index) === Number(messages.length - 1)) && (Number(initialMessage?.status) === 4)) ?
                <div className='w-100 text-center text-gray-700 fs-5 fw-bold pt-5'>
                  Closed {prettyDate(message.created_at)}
                </div> : null
              }
            </div>
          </div>
        )
      })}
      <div ref={messagesEndRef}/>
    </>
  )
}

export default ChatBodyScrollBar
