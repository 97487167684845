import React, {useEffect, useState} from 'react'
import {Button} from "reactstrap"
import {clsx} from "clsx"
import {capitalize} from "lodash"
import {useSecuritiesContext} from "@metronic/Components/Securities/Context/SecuritiesContext"
import {blockCardTitle, TablePagination, handleNextPage, handlePrevPage} from "@metronic/Components/Securities/Components.jsx"
import {SecurityDetail} from "@metronic/Components/Securities/Blocks/index.jsx"
import {checkImageValidity} from "@metronic/helpers/utils.jsx"
import {getAcquiredSecurities} from "@metronic/api/securities.js"


const SecuritiesTable = ({blockId}) => {
  const {
    tabData,
    ownSelectedSecurities,
    setOwnSelectedSecurities
  } = useSecuritiesContext()
  const [blockData, setBlockData] = useState({})
  const [isMouseOnRow, setIsMouseOnRow] = useState(0)
  const recordsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1)
  const [currentRecords, setCurrentRecords] = useState([])
  const [totalPages, setTotalPages] = useState(Number())
  const [imageValidity, setImageValidity] = useState({})
  const [tablesData, setTablesData] = useState([])
  const [acquiredSecurities, setAcquiredSecurities] = useState([])

  useEffect(() => {
    if (tabData && tabData.length && blockId) {
      setBlockData(tabData.find(block => block.uuid === blockId))
    }
  }, [tabData, blockId])

  useEffect(() => {
    getAcquiredSecurities().then(r => {
      setAcquiredSecurities(r)
    })
  }, [])

  useEffect(() => {
    if (acquiredSecurities && acquiredSecurities.length) {
      setTablesData(acquiredSecurities.flatMap(i => i.securities))
    }
  }, [acquiredSecurities])

  const resetSelectedSecurities = () => {
    setOwnSelectedSecurities({})
  }

  useEffect(() => {
    const validateImages = async () => {
      const results = {}
      for (const item of tablesData) {
        const meta = item.meta
        if (meta['logo'].value) {
          const isValid = await checkImageValidity(meta['logo'].value)
          results[item.id] = isValid
        } else {
          results[item.id] = false
        }
      }
      setImageValidity(results)
    }

    validateImages()
  }, [tablesData])

  return (
    <>
      {blockCardTitle(blockData.title)}

      <TablePagination
        securitiesList={tablesData}
        blockData={blockData}
        currentPage={currentPage}
        recordsPerPage={recordsPerPage}
        setCurrentRecords={setCurrentRecords}
        setTotalPages={setTotalPages}
      />

      {!ownSelectedSecurities?.name ?
        <div className="pt-0">
          <div className="table-responsive">
            <table id="kt_profile_overview_table"
                   className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bold">
              <thead className="fs-7 text-gray-500 text-uppercase">
              <tr>
                <th className="min-w-250px">Name</th>
                <th className="min-w-150px">Maturity date</th>
                <th className="min-w-90px">Profitability</th>
                <th className="min-w-90px">Price</th>
              </tr>
              </thead>
              {(currentRecords && currentRecords.length) ? <tbody className="fs-6">
                {currentRecords.map((item, idx) => {
                  const id = idx + 1
                  const meta = item.meta
                  const isValid = imageValidity[item.id]

                  return (
                    <tr key={idx}
                        onClick={() => setOwnSelectedSecurities(
                          currentRecords[idx]
                        )}
                        onMouseEnter={() => setIsMouseOnRow(id)}
                        onMouseLeave={() => setIsMouseOnRow(0)}
                        className={clsx('', isMouseOnRow === id && 'bg-light')}
                    >
                      <td className="cursor-pointer">
                        <div className="d-flex align-items-center">

                          {meta['logo']?.value && isValid !== undefined ? (
                            isValid ? (
                              <div className="symbol symbol-35px ms-1 me-2">
                                <img src={meta['logo'].value} alt="image"/>
                              </div>
                            ) : null
                          ) : null}

                          <div className="d-flex flex-column justify-content-center">
                            <span className="fs-6 text-gray-800">
                              {item.name}
                              {isMouseOnRow === id &&
                                <i className="bi bi-chevron-right text-primary ms-5"></i>
                              }
                            </span>
                            <div className="fw-semibold text-gray-500">{capitalize(item.type)}</div>
                          </div>
                        </div>
                      </td>
                      <td>{meta['maturity_date']?.value || '-'}</td>
                      <td>{meta['coupon_rate']?.value || '-'} %</td>
                      <td>{meta['current_price']?.value || '-'} {meta['payout_asset']?.payout_asset_code || ''}</td>
                    </tr>
                  )
                })
                }
                </tbody> :
                null
              }
            </table>

            {!currentRecords?.length ?
              <h1 className="text-center py-5 w-100 fs-1 text-gray-600">
                No data
              </h1> : null
            }

          </div>
          {totalPages ? <div className="d-flex justify-content-center align-items-center mt-4">
              <Button type="button" onClick={() => handlePrevPage(setCurrentPage)} disabled={currentPage === 1}
                      className="btn btn-light-success px-2 py-1 me-5 d-flex justify-content-center align-items-center">
                <i className="bi bi-chevron-double-left"></i>
              </Button>
              <span className="fs-6 fw-bold text-primary">Page {currentPage} of {totalPages}</span>
              <Button type="button" onClick={() => handleNextPage(setCurrentPage, totalPages)} disabled={currentPage === totalPages}
                      className="btn btn-light-success px-2 py-1 ms-5 d-flex justify-content-center align-items-center">
                <i className="bi bi-chevron-double-right"></i>
              </Button>
            </div>
            : null
          }
        </div> : null
      }

      <SecurityDetail
        acquiredSecurity={Object.values(acquiredSecurities).find(i => i.security_id === ownSelectedSecurities?.id)}
        isOwnDetails={true}
        selectedSecurities={ownSelectedSecurities}
        resetSelectedSecurities={resetSelectedSecurities}
      />
    </>
  )
}

export default SecuritiesTable
