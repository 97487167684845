import React from 'react'
import {Card, CardBody, Col, Row} from "reactstrap"
import {Toolbar} from "@metronic/_metronic/layout/components/toolbar/Toolbar"
import {Content} from "@metronic/_metronic/layout/components/Content"
import {useSecuritiesContext} from "@metronic/Components/Securities/Context/SecuritiesContext"

// Blocks
import {
  GeneralInformationBlock,
  GeneralTabs,
  PopularList,
  HowToBuy,
  SecuritiesTable,
  DocumentsAndInformation,
  OwnSecuritiesTable,
} from "@metronic/Components/Securities/Blocks"

const blocksMapper = {
  description: (blockId) => <GeneralInformationBlock blockId={blockId} />,
  popular_list: (blockId) => <PopularList blockId={blockId} />,
  how_buy: (blockId) => <HowToBuy blockId={blockId} />,
  securities_table: (blockId) => <SecuritiesTable blockId={blockId} />,
  own_securities_table: (blockId) => <OwnSecuritiesTable blockId={blockId} />,
  documents_and_info: (blockId) => <DocumentsAndInformation blockId={blockId} />,
}

const SecuritiesContent = () => {
  const {tabData} = useSecuritiesContext()

  return (
    <>
      <Toolbar/>
      <Content>

        <>
          {tabData && tabData.length ?
            tabData.map((block, idx) => {
              let blockName = block.name
              const blockId = block.uuid

              if (blockName === 'securities_table' && block.is_user_list) {
                blockName = 'own_securities_table'
              }

              const BlockComponent = blocksMapper[blockName]

              return (
                <Row key={idx} className="row-cols-1 g-5 g-xl-10 mb-5 mb-xl-10">
                  <Col>
                    <Card>
                      <CardBody>
                        {idx === 0 ? <GeneralTabs /> : null}
                        {BlockComponent ? BlockComponent(blockId) : null}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )
            })
            : null
          }
        </>

      </Content>
    </>
  )
}

export default SecuritiesContent
