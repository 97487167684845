import React, {useEffect, useState} from 'react'
import {Button, Col, Row} from "reactstrap"
// Components
import {Content} from "@metronic/_metronic/layout/components/Content"
import {Toolbar} from "@metronic/_metronic/layout/components/toolbar/Toolbar"
import ChatBody from "@metronic/Components/Support/ChatBody"
import ChatList from "@metronic/Components/Support/ChatList"
import {Dropdown1} from "@metronic/_metronic/partials"
import {usePage} from "@inertiajs/react"
import {capitalize} from "lodash"
import {clsx} from "clsx"
import {reloadData} from "@metronic/helpers/utils.jsx"

const SupportContent = () => {
  const {props: pageProps} = usePage()
  const [ticketsList, setTicketsList] = useState([])
  const [ticket, setTicket] = useState({})
  const [subject, setSubject] = useState('')
  const [status, setStatus] = useState('')
  const [statusColor, setStatusColor] = useState('primary')
  const [initialMessage, setInitialMessage] = useState({})
  const [comments, setComments] = useState([])
  const userId = pageProps.auth.user.id

  useEffect(() => {
    setTicketsList(pageProps?.tickets?.data ?? [])
    setTicket(pageProps.active_ticket ?? [])
  }, [pageProps])

  useEffect(() => {
    setSubject(ticket.subject)
    setStatus(ticket.status_name)
    setStatusColor(ticket.status_color === 'secondary' ? 'primary' : ticket.status_color)
    setInitialMessage((Number(ticket.length) !== 0) ? {
      created_at: ticket.created_at,
      deleted_at: ticket.deleted_at,
      message: ticket.message,
      user_id: ticket.customer_id,
      status: ticket.status,
      last_update: ticket.updated_at
    } : false)
    setComments(ticket.comments)
  }, [ticket])

  return (
    <>
      <Toolbar/>
      <Content>
        <Row className="g-5 g-xl-10 mb-5 mb-xl-10">
          <Col className='flex-column col-12 col-lg-5 flex-lg-row-auto'>
            <ChatList ticketsList={ticketsList} />
          </Col>

          <Col className="col-12 col-lg-7">
            <div className='flex-lg-row-fluid'>
              <div className='card' id='kt_chat_messenger' style={{paddingBottom: '8px'}}>
                <div className='card-header' id='kt_chat_messenger_header'>
                  <div className={clsx('card-title text-truncate d-flex justify-content-between w-100')}>
                    <div>
                      <div className='symbol-group symbol-hover'></div>
                      {(Number(ticket.length) !== 0) ?
                        <div className='d-flex justify-content-center'>
                          <div className='symbol symbol-45px symbol-circle'>
                        <span className='symbol-label bg-light-primary text-primary fs-4 fw-bolder'>
                          {subject && capitalize(subject.split('', 1)[0])}
                        </span>
                          </div>
                          <div className="ms-5">
                        <span>
                          {subject}
                        </span>

                            <div className='mb-0 lh-1'>
                            <span
                              className={`fs-7 fw-bold bg-${statusColor} text-inverse-${statusColor} rounded-2 px-1`}>{status}</span>
                            </div>
                          </div>
                        </div> :
                        <div className="text-gray-700 w-100 fs-2 text-center w-100 fw-bold">Chat</div>
                      }
                    </div>

                    <Button
                      color="text-muted"
                      outline
                      className="hstack justify-content-center text-muted align-items-center px-1"
                      onClick={() => reloadData(['tickets', 'active_ticket'])}
                    >
                      <i className="bi bi-bootstrap-reboot fs-2 refresh"></i>
                    </Button>

                  </div>

                  {/*{(Number(ticket.length) !== 0) &&*/}
                  {/*  <div className='card-toolbar'>*/}
                  {/*    <div className='me-n3'>*/}
                  {/*      <button*/}
                  {/*        className='btn btn-sm btn-icon btn-active-light-primary'*/}
                  {/*        data-kt-menu-trigger='click'*/}
                  {/*        data-kt-menu-placement='bottom-end'*/}
                  {/*        data-kt-menu-flip='top-end'*/}
                  {/*      >*/}
                  {/*        <i className='bi bi-three-dots fs-2'></i>*/}
                  {/*      </button>*/}
                  {/*      /!*<Dropdown1/>*!/*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*}*/}
                </div>

                <ChatBody ticketId={ticket.id} initialMessage={initialMessage} comments={comments} userId={userId}/>

              </div>
            </div>
          </Col>
        </Row>
      </Content>
    </>
  )
}

export default SupportContent
