import React, {FC} from 'react'
import clsx from "clsx"
import {ThemeModeSwitcher} from '@metronic/_metronic/partials'
import {NotificationsDropdown} from '@metronic/Components/HeaderDropdowns'
import {usePage} from "@inertiajs/react"
import {KTIcon} from "../../../../helpers";
import {capitalize} from "lodash";
import {Tooltip} from "react-tooltip"

const toolbarButtonMarginClass = 'ms-1 ms-lg-3'

const TopbarLight: FC = () => {
  // const {props: pageProps} = usePage()
  const notificationsData = usePage().props?.auth?.notifications?.data
  const verificationStatus = usePage().props?.auth?.user?.profile?.verification_status

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        <div className="d-flex w-100">
          {/* Account Balance dropdown BEGIN */}
          {/*<div className={clsx('d-flex align-items-center shadow-xs bg-light-subtle rounded-circle', toolbarButtonMarginClass)}>*/}
          {/*  <div className={clsx('btn btn-icon btn-circle btn-color-active-info position-relative'*/}
          {/*  )}*/}
          {/*  >*/}
          {/*    <AccountBalanceDropdown/>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/* Account Balance dropdown END */}


          <div className={clsx('d-flex align-items-center shadow-xs bg-light-subtle rounded-circle',
            toolbarButtonMarginClass)}
          >
            <div className={clsx('btn btn-icon btn-circle position-relative')}>
              <span
                data-tooltip-id={'ver_aside'}
                data-tooltip-content={capitalize(verificationStatus)}
              >
                <KTIcon iconName='verify' className={clsx('fs-2x',
                  verificationStatus === 'verified' ? 'text-success' : 'text-muted'
                )}/>
                <Tooltip id={'ver_aside'}/>
              </span>
            </div>
          </div>


          {/* BEGIN Notifications dropdown */}
          <div className={clsx('d-flex align-items-center shadow-xs bg-light-subtle rounded-circle',
            toolbarButtonMarginClass)}
          >
            <div className={clsx('btn btn-icon btn-circle position-relative')}
            >
              <NotificationsDropdown/>
              {(notificationsData?.length && notificationsData?.filter(i => !i.read_at)?.length) ?
                <span className="pulse-ring"></span> : null
              }
            </div>
          </div>
          {/* END Notifications dropdown */}

          {/* Theme mode dropdown BEGIN */}
          {/*<div className={clsx('d-flex align-items-center shadow-xs bg-light-subtle rounded-circle',*/}
          {/*  toolbarButtonMarginClass)}*/}
          {/*>*/}
          {/*  <ThemeModeSwitcher/>*/}
          {/*</div>*/}
          {/* Theme mode dropdown END */}
        </div>

      </div>
    </div>
  )
}

export {TopbarLight}
