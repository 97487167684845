import React, {FC} from 'react'
import clsx from 'clsx'
// import {KTIcon, toAbsoluteUrl} from '../../../../helpers'
import {
  // HeaderNotificationsMenu,
  HeaderUserMenu,
  // QuickLinks,
  // Search,
  ThemeModeSwitcher,
} from '../../../../partials'
import {NotificationsDropdown} from '@metronic/Components/HeaderDropdowns'
import defaultAvatar from '@metronic/assets/images/users/user-dummy-img.jpg'
import {usePage} from "@inertiajs/react"
import {KTIcon} from "../../../../helpers"
import {Tooltip} from "react-tooltip"
import {capitalize} from "lodash"
import {useThemeMode} from "../../../../partials/layout/theme-mode/ThemeModeProvider"

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40p',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'fs-1'

const TopbarDefault: FC = () => {
  const {props: pageProps} = usePage()
  const notificationsData = pageProps?.auth?.notifications?.data
  const verificationStatus = usePage().props?.auth?.user?.profile?.verification_status
  const {mode} = useThemeMode()

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>

        {/* Search */}
        {/*<div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>*/}
        {/*  <Search />*/}
        {/*</div>*/}
        {/* Activities */}
        {/*<div className={clsx('d-flex align-items-center ', toolbarButtonMarginClass)}>*/}
        {/*  /!* begin::Drawer toggle *!/*/}
        {/*  <div*/}
        {/*    className={clsx(*/}
        {/*      'btn btn-icon btn-active-light-primary btn-custom',*/}
        {/*      toolbarButtonHeightClass*/}
        {/*    )}*/}
        {/*    id='kt_activities_toggle'*/}
        {/*  >*/}
        {/*    <KTIcon iconName='chart-simple' className={toolbarButtonIconSizeClass} />*/}
        {/*  </div>*/}
        {/*  /!* end::Drawer toggle *!/*/}
        {/*</div>*/}

        {/* NOTIFICATIONS */}
        {/*<div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>*/}
        {/*  /!* begin::Menu- wrapper *!/*/}
        {/*  <div*/}
        {/*    className={clsx(*/}
        {/*      'btn btn-icon btn-active-light-primary btn-custom',*/}
        {/*      toolbarButtonHeightClass*/}
        {/*    )}*/}
        {/*    data-kt-menu-trigger='click'*/}
        {/*    data-kt-menu-attach='parent'*/}
        {/*    data-kt-menu-placement='bottom-end'*/}
        {/*    data-kt-menu-flip='bottom'*/}
        {/*  >*/}
        {/*    <KTIcon iconName='element-plus' className={toolbarButtonIconSizeClass} />*/}
        {/*  </div>*/}
        {/*  <HeaderNotificationsMenu />*/}
        {/*  /!* end::Menu wrapper *!/*/}
        {/*</div>*/}

        {/* CHAT */}


        {/* Quick links */}
        {/*<div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>*/}
        {/*    /!* begin::Menu wrapper *!/*/}
        {/*    <div*/}
        {/*        className={clsx(*/}
        {/*            'btn btn-icon btn-active-light-primary btn-custom',*/}
        {/*            toolbarButtonHeightClass*/}
        {/*        )}*/}
        {/*        data-kt-menu-trigger='click'*/}
        {/*        data-kt-menu-attach='parent'*/}
        {/*        data-kt-menu-placement='bottom-end'*/}
        {/*        data-kt-menu-flip='bottom'*/}
        {/*    >*/}
        {/*        <KTIcon iconName='element-11' className={toolbarButtonIconSizeClass}/>*/}
        {/*    </div>*/}
        {/*    <QuickLinks/>*/}
        {/*    /!* end::Menu wrapper *!/*/}
        {/*</div>*/}

        {/* Account Balance dropdown BEGIN */}
        {/*<div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>*/}
        {/*    <div className={clsx('btn btn-icon btn-active-light-primary btn-custom position-relative',*/}
        {/*        toolbarButtonHeightClass*/}
        {/*    )}*/}
        {/*    >*/}
        {/*        <AccountBalanceDropdown/>*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/* Account Balance dropdown END */}

        <div className={clsx('d-flex align-items-center')}
        >
          <div className={clsx('btn btn-icon btn-circle position-relative')}>
              <span
                data-tooltip-id={'ver_aside'}
                data-tooltip-content={capitalize(verificationStatus)}
              >
                <KTIcon iconName='verify' className={clsx('fs-2x',
                  verificationStatus === 'verified' ? 'text-success' :
                    (mode === 'light' || mode === 'system') ? 'text-gray-100' : 'text-body'
                )}/>
                <Tooltip id={'ver_aside'}/>
              </span>
          </div>
        </div>

        {/* Notifications dropdown BEGIN */}
        <div className={clsx('d-flex align-items-center me-lg-0 me-1')}>
          <div className={clsx('btn btn-icon btn-active-light-primary btn-custom position-relative',
            toolbarButtonHeightClass
          )}
          >
            <NotificationsDropdown/>
            {(notificationsData?.length && notificationsData?.filter(i => !i.read_at)?.length) ?
              <span
                className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span> :
              null
            }

          </div>
        </div>
        {/* Notifications dropdown END */}

        {/* Theme mode dropdown BEGIN */}
        {/*<div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>*/}
        {/*  <ThemeModeSwitcher toggleBtnClass={toolbarButtonHeightClass}/>*/}
        {/*</div>*/}
        {/* Theme mode END */}

        {/* User dropdown BEGIN */}
        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          <div
            className={clsx('cursor-pointer symbol ms-1', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <img
              className='h-30px w-30px rounded object-fit-cover'
              src={pageProps?.auth?.user?.profile?.avatar_url ? pageProps?.auth?.user?.profile?.avatar_url : defaultAvatar}
              alt='avatar'
            />
          </div>
          <HeaderUserMenu/>
        </div>
        {/* User dropdown END */}

      </div>
    </div>
  )
}

export {TopbarDefault}
