import React, {createContext, useContext, useEffect, useRef, useState} from 'react'

const SecuritiesContext = createContext({})

export const useSecuritiesContext = () => {
  return useContext(SecuritiesContext)
}

export const SecuritiesProvider = ({ children, page_data, customer_securities, ...props }) => {
  const [activeTab, setActiveTab] = useState('')
  const [securitiesList, setSecuritiesList] = useState([])
  const [activeBondId, setActiveBondId] = useState(0)
  const [activeBond, setActiveBond] = useState({})
  const [tabList, setTabList] = useState([])
  const [allListTab, setAllListTab] = useState('')
  const [ownListTab, setOwnListTab] = useState('')
  const [tabData, setTabData] = useState([])
  const [purchaseList, setPurchaseList] = useState([])
  const tableRef = useRef(null)
  const [ownSelectedSecurities, setOwnSelectedSecurities] = useState('')

  const [data, setData] = useState([])

  useEffect(() => {
    if (page_data) {
      setData(page_data)

      if (page_data?.securities?.length) {
        setSecuritiesList(page_data.securities)
      }

    }
  }, [page_data])

  useEffect(() => {
    if (customer_securities && customer_securities.length) {
      setPurchaseList(customer_securities)
    }
  }, [customer_securities])

  useEffect(() => {
    if (data && data.tabs) {
      setTabList(data.tabs.map((item) => {
        return ({
          id: item.id,
          title: item.title
        })
      }))

      const allListBlock = data.tabs.find(tab =>
        tab.blocks?.find(block => block.name === 'securities_table' && block.is_user_list === null))?.id

      const ownListBlock = data.tabs.find(tab =>
        tab.blocks?.find(block => block.name === 'securities_table' && block.is_user_list))?.id

      setAllListTab(allListBlock || '')
      setOwnListTab(ownListBlock || '')
    }
  }, [data])

  useEffect(() => {
    if (tabList && tabList.length) {
      setActiveTab(String(tabList[0]?.id))
    }
  }, [tabList])

  useEffect(() => {
    if (data && activeTab && activeTab.length) {
      setTabData(data.tabs.find(tab => tab.id === activeTab)?.blocks)
    }
  }, [data, activeTab])

  useEffect(() => {
    if (ownSelectedSecurities.name && (activeTab !== ownListTab)) {
      setOwnSelectedSecurities({})
    }
  }, [activeTab, ownSelectedSecurities])

  return (
    <SecuritiesContext.Provider
      value={{
        ...props,
        activeTab, setActiveTab,
        securitiesList, setSecuritiesList,
        activeBondId, setActiveBondId,
        activeBond, setActiveBond,
        tabList, setTabList,
        ownSelectedSecurities, setOwnSelectedSecurities,
        data,
        allListTab,
        ownListTab,
        tabData,
        tableRef,
        purchaseList
    }}>
      {children}
    </SecuritiesContext.Provider>
  )
}
