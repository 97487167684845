import React, {useEffect, useState} from 'react'
import {Card} from "reactstrap"
import {clsx} from "clsx"
import {capitalize} from "lodash"
import {useSecuritiesContext} from "@metronic/Components/Securities/Context/SecuritiesContext.jsx"
import {allListLink, blockCardTitle} from "@metronic/Components/Securities/Components.jsx"
import {CalculationBlock} from "@metronic/Components/Securities/Blocks"
import {checkImageValidity} from "@metronic/helpers/utils.jsx"

const cardsClasses = 'p-3 d-flex flex-column card cursor-pointer w-250px w-md-350px p-lg-3 mx-lg-2 w-lg-250px ' +
  'p-xl-5 mx-xl-0 w-xl-325px mx-xxl-1 w-xxl-375px'

const PopularList = ({blockId}) => {
  const [isMouseOnCard, setIsMouseOnCard] = useState(0)
  const {activeBondId, setActiveBondId, setActiveBond, tabData, securitiesList, allListTab} = useSecuritiesContext()
  const [popularList, setPopularList] = useState([])
  const [imageValidity, setImageValidity] = useState({})

  const selectBondHandler = (item, idx) => {
    setActiveBondId(idx)
    setActiveBond(item)
  }

  useEffect(() => {
    if (securitiesList.length) {
      setPopularList(securitiesList.filter(i => i.meta?.popular?.value))
    }
  }, [securitiesList])

  useEffect(() => {
    const validateImages = async () => {
      const results = {}

      for (const item of popularList) {
        const meta = item.meta
        if (meta['logo']?.value) {
          results[item.id] = await checkImageValidity(meta['logo']?.value)
        } else {
          results[item.id] = false
        }
      }
      setImageValidity(results)
    }

    validateImages()
  }, [popularList])

  return (
    <>
      {blockCardTitle(tabData.find(i => i.uuid === blockId)?.title)}

      <div className="scroll-x overflow-auto d-flex flex-row gap-5 gap-xl-4 gap-xxl-5 pb-5 pt-3 px-3">
        {(popularList && popularList.length) ? popularList.map((item, idx) => {
          const id = idx + 1
          const meta = item.meta
          const isValid = imageValidity[item.id]

            return (
            <div key={idx}>
              <Card
                className={clsx('', cardsClasses,
                  isMouseOnCard === id && 'bg-light-primary shadow-sm',
                  activeBondId === id && 'shadow-sm bg-light-success'
                )}
                onClick={() => selectBondHandler(item, id)}
                onMouseEnter={() => setIsMouseOnCard(id)}
                onMouseLeave={() => setIsMouseOnCard(0)}
              >
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-column">
                    <span className="text-muted fs-7 pb-2 fw-bold">{capitalize(item.type)}</span>
                    <h4 className="text-gray-700">{item.name}</h4>
                  </div>
                  <div>
                    {meta['logo']?.value && isValid !== undefined ? (
                      isValid ? (
                        <div className="symbol symbol-50px symbol-circle shadow">
                          <img src={meta['logo'].value} alt="image"/>
                        </div>
                      ) : null
                    ) : null}
                  </div>
                </div>

                <div className={clsx('separator mt-4 mb-3 w-75',
                  isMouseOnCard === id && 'border-primary opacity-25',
                  activeBondId === id && 'border-primary opacity-25'
                )}></div>

                <div className="d-flex flex-column mb-4">
                  <span className="text-muted fs-7 fw-bold pb-1">Maturity date</span>
                  <span className="text-gray-700 fs-6 fw-bold">
                    {meta['maturity_date'] ? meta['maturity_date'].value : '-'}
                  </span>
                </div>

                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-column">
                    <span className="text-muted fs-7 fw-bold pb-1">Coupon rate</span>
                    <span className="text-gray-700 fs-6 fs-lg-7 fs-xl-6 fw-bold">
                      {meta['coupon_rate'] ? meta['coupon_rate'].value : '-'} %
                    </span>
                  </div>

                  <div className="d-flex flex-column">
                    <span className="text-muted fs-7 fw-bold pb-1">Price</span>
                    <span className="text-gray-700 fs-6 fs-lg-7 fs-xl-6 fw-bold">
                      {meta['current_price'] ? meta['current_price'].value : '-'} {meta['payout_asset']?.payout_asset_code || ''}
                    </span>
                  </div>
                </div>
              </Card>
            </div>
          )}) :
          <h3 className="text-gray-600">The list is currently unavailable</h3>
        }
      </div>

      {allListTab ? allListLink() : null}

      {tabData && tabData?.find(i => i.uuid === blockId)?.is_calculator_visible ?
        <CalculationBlock blockId={blockId}/> : null
      }

    </>
  )
}

export default PopularList
